@font-face {
  font-family: 'sohne';
  src: url('./fonts/sohne/sohne-extrafett-webfont.woff2') format('woff2'),
    url('./fonts/sohne/sohne-extrafett-webfont.woff') format('woff');
  font-weight: 900;
  font-style: normal;

}

@font-face {
  font-family: 'sohne';
  src: url('./fonts/sohne/sohne-fett-webfont.woff2') format('woff2'),
    url('./fonts/sohne/sohne-fett-webfont.woff') format('woff');
  font-weight: 800;
  font-style: normal;

}

@font-face {
  font-family: 'sohne';
  src: url('./fonts/sohne/sohne-dreiviertelfett-webfont.woff2') format('woff2'),
    url('./fonts/sohne/sohne-dreiviertelfett-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;

}


@font-face {
  font-family: 'sohne';
  src: url('./fonts/sohne/sohne-halbfett-webfont.woff2') format('woff2'),
    url('./fonts/sohne/sohne-halbfett-webfont.woff') format('woff');
  font-weight: 600;
  font-style: normal;

}



@font-face {
  font-family: 'sohne';
  src: url('./fonts/sohne/sohne-kraftig-webfont.woff2') format('woff2'),
    url('./fonts/sohne/sohne-kraftig-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;

}


@font-face {
  font-family: 'sohne';
  src: url('./fonts/sohne/sohne-buch-webfont.woff2') format('woff2'),
    url('./fonts/sohne/sohne-buch-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;

}

@font-face {
  font-family: 'sohne';
  src: url('./fonts/sohne/sohne-leicht-webfont.woff2') format('woff2'),
    url('./fonts/sohne/sohne-leicht-webfont.woff') format('woff');
  font-weight: 300;
  font-style: normal;

}

@font-face {
  font-family: 'sohne';
  src: url('./fonts/sohne/sohne-extraleicht-webfont.woff2') format('woff2'),
    url('./fonts/sohne/sohne-extraleicht-webfont.woff') format('woff');
  font-weight: 200;
  font-style: normal;

}

/* CANELA TYPEFACE */



@font-face {
  font-family: 'canela';
  src: url('./fonts/canela/canela-black-webfont.woff2') format('woff2'),
       url('./fonts/canela/canela-black-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;

}


@font-face {
  font-family: 'canela';
  src: url('./fonts/canela/canela-bold-webfont.woff2') format('woff2'),
       url('./fonts/canela/canela-bold-webfont.woff') format('woff');
  font-weight: 600;
  font-style: normal;

}


@font-face {
  font-family: 'canela';
  src: url('./fonts/canela/canela-medium-webfont.woff2') format('woff2'),
       url('./fonts/canela/canela-medium-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;

}



@font-face {
  font-family: 'canela';
  src: url('./fonts/canela/canela-regular-webfont.woff2') format('woff2'),
       url('./fonts/canela/canela-regular-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;

}

@font-face {
  font-family: 'canela';
  src: url('./fonts/canela/canela-light-webfont.woff2') format('woff2'),
       url('./fonts/canela/canela-light-webfont.woff') format('woff');
  font-weight: 300;
  font-style: normal;

}



@font-face {
  font-family: 'canela';
  src: url('./fonts/canela/canela-thin-webfont.woff2') format('woff2'),
       url('./fonts/canela/canela-thin-webfont.woff') format('woff');
  font-weight: 200;
  font-style: normal;

}


body {
  margin: 0;
  font-family: 'sohne', sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}