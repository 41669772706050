#create-exploration-modal {
    font-family: 'sohne';
    border-radius: 20px;
    background: #FAFAFD;
    box-shadow: 0px 5px 40px 0px rgba(25, 17, 61, 0.15);
    width: 978px;
    height: 567px;
    display: flex;
    flex-direction: column;
    z-index: 5;
}

#create-exploration-modal-blur {
    width: 100%;
    height: 100%;
    background: none;
    backdrop-filter: blur(5px);
    position: absolute;
    z-index: 3;
}

#create-exploration-modal-header {
    display: flex;
    width: 100%;
    height: 57px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 20px 20px 0px 0px;
    background: #E8E6F2;
}

#create-exploration-modal-content {
    width: auto;
    height: 567px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0;
    padding: 48px;
    gap: 36px;
}

#create-exploration-modal-content h4 {
    font-size: 22px;
    font-weight: 500;
    margin: 0;
}

#forms {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
}

#main-question-form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
}

#main-question-form-label {
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
}

#main-question-form input {
    width: 844px;
    height: 64px;
    border-radius: 20px;
    border: 1px solid #19113D;
    background: #FFF;
    padding-left: 16px;
    font-weight: 400;
    font-size: 14px;
}

#background-question-form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
}

#background-question-form-label {
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
}

#background-question-form input {
    width: 882px;
    height: 97px;
    flex-shrink: 0;
    border-radius: 20px;
    border: 1px solid #19113D;
    background: #FFF;
    padding-left: 16px;
    font-weight: 400;
    font-size: 14px;
}

#create-exploration-button {
    margin: 0;
    border-radius: 100px;
    border: none;
    background: #575073;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 12px;
    padding: 12px 20px;
    cursor: pointer;
}

#create-exploration-button p {
    margin: 0;
    color: white;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
}