.spinner {
    animation: rotator 1.2s linear infinite;
    width: 120px;
    height: 120px;
}

@keyframes rotator {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(270deg);
    }
}

.path {
    stroke-dasharray: 187;
    stroke-dashoffset: 0;
    transform-origin: center;
    animation:
        dash 1.2s cubic-bezier(.455, .03, .515, .955) infinite,
        colors 5.6s cubic-bezier(.455, .03, .515, .955) infinite; /* Assuming you meant duration * 4 for colors */
}


@keyframes colors {
    0% {
        stroke: #DEDAF2;
    }
    25% {
        stroke: #8D87A8;
    }
    50% {
        stroke: #575073;
    }
    75% {
        stroke: #19113D;
    }
    100% {
        stroke: #DEDAF2;
    }
}

@keyframes dash {
    0% {
        stroke-dashoffset: 187;
    }
    50% {
        stroke-dashoffset: 46.75; /* 187 / 4 */
        transform: rotate(135deg);
    }
    100% {
        stroke-dashoffset: 187;
        transform: rotate(450deg);
    }
}