
.node {
    font-family: 'sohne';
    padding: 14px;
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    border-radius: 8px;
    cursor: pointer;
    user-select: none;
    position: absolute;
    max-width: 250px;
    text-align: center;
    transition: 0.15s cubic-bezier(.25, .46, .45, .94);
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: space-evenly;
    backdrop-filter: blur(200px);
}

#node-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 6px;
    padding: 0;
}

#read-indicator {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 5px 10px;
    border-radius: 100px;
    font-size: 14px;
    text-align: left;
    flex-grow: 0;
    width: fit-content;
    color: #F6F5FA;
    border: 1px dashed #F6F5FA;
    order: 0;
    transition: 0.15s cubic-bezier(.25, .46, .45, .94);
    font-weight: 400;
}

.node p {
    color: #FFF;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    text-align: left;
    align-items: flex-start;
    margin: 0;
    padding: 0;
}

#node-box {
    display: flex;
    flex-direction: column;
    gap: 100px;
}

#add-subnode-button {
    width: 28px;
    height: 28px;
    background-color: rgba(87, 80, 115, 0.7);
    position: absolute;
    top: 115%;
    align-self: center;
    border-radius: 100px;
    color: white;
    border: none;
    font-size: 16px;
    cursor: pointer;
}

#add-subnode-active {
    outline: 2px dashed rgba(87, 80, 115, 1);
    outline-offset: 4px;
    transition: 0.15s cubic-bezier(.25, .46, .45, .94);
}

#add-subnode-hover {
    outline: 2px dashed rgba(87, 80, 115, 0.35);
    outline-offset: 4px;
    transition: 0.15s cubic-bezier(.25, .46, .45, .94);
}

#add-childnode-input {
    height: 50px;
    outline: 2px dashed rgba(87, 80, 115, 1);
    padding: 4px;
    padding-left: 14px;
    position: absolute;
    top: 120%;
    align-self: center;
    color:  rgba(87, 80, 115, 1);
    font-size: 17px;
    border-radius: 8px;
    text-wrap: wrap;
    width: 264px;
    text-align: left;
    backdrop-filter: blur(200px);
    border: none;
}

#node-expand-button {
    display: flex;
    flex-direction: row;
    gap: 4px;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 6px 12px;
    transition: 0.15s cubic-bezier(.25, .46, .45, .94);
    justify-content: flex-end;
    align-items: center;
}

#node-expand-button p {
    font-size: 14px;
    font-weight: 500;
}

.node svg {
    flex-shrink: 0;
    align-self: center;
    transition: 0.15s cubic-bezier(.25, .46, .45, .94);
}

#node-expand-button:hover {
    transform: scale(1.02);
}

#node-text {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

#node-text-header {
    text-align: left;
    font-size: 17px;
    font-weight: 600;
    color: white;
}