.canvas-area {
  font-family: 'sohne';
  position: relative;
  background-color: #F6F5FA;
  border: 1px solid #eaeaea;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.zoom-controls {
  position: fixed;
  bottom: 14px;
  right: 14px;
  background: #F6F5FA;
  border: 1px solid #DEDAF2;
  border-radius: 5px;
  padding: 5px;
  z-index: 2;
  color: #19113D;
}

.zoom-controls button {
  border: none;
  background: none;
  cursor: pointer;
  color: #19113D;
}

.zoom-controls button:disabled {
  cursor: default;
  opacity: 0.5;
}

.zoom-controls span {
  margin: 0 10px;
}

.canvas-container {
  position: relative;
  /* Or absolute/fixed depending on your layout */
  /* This is important to prevent scrollbars on the container */
  width: 100%;
  height: 100%;
}

.grid-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  /* The background styling goes here, as defined in gridStyle */
}

.lines-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: visible;
}

#canvas-content {
  overflow: visible;
  width: 100%;
  height: 100%;
}

#synthesis-button {
  display: flex;
  margin: 0;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: white;
  position: fixed;
  top: 14px;
  right: 14px;
  z-index: 1;
  color: #575073;
  border: 0.5px solid #575073;
  gap: 4px;
  transition: 0.15s cubic-bezier(.25, .46, .45, .94);
}

#synthesis-button p {
  margin: 0;
  padding: 0;
}

#synthesis-button:hover {
  cursor: pointer;
  transform: scale(1.01);
}