.app {
  font-family: 'sohne';
  text-align: center;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  width: 100vw;
  height: 100vh;
}

.question-input-container {
  margin: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.question-input-container input {
  width: 500px;
  padding: 0.8rem;
  border-radius: 100px;
  border: 1px solid #19113D;
  background: #FFF;
  color: #19113D;
}

.question-input-container button {
  width: 100px;
  border-radius: 100px;
  padding: 0.8rem;
  border: none;
  color: white;
  background-color: #19113D;
  cursor: pointer;
}

.lines-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  /* Allows click events to pass through to elements below */
}

.context-input-container {
  margin: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.context-input-container input {
  width: 630px;
  height: 40px;
  padding: 0.8rem;
  padding-left: 0.8rem;
  border-radius: 16px;
  border: 1px solid #19113D;
  background: #FFF;
  color: #19113D;
}

.explorations {
  display: flex;
  flex-direction: column;
  width: 250px;
  padding: 28px;
  padding-right: 20px;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  background: #19113D;
  gap: 28px;
}

.exploration-action {
  display: flex;
  flex-direction: row;
  width: 281px;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  border: none;
  border-radius: 100px;
  padding: 0 18px 0 18px;
  transition: 0.15s cubic-bezier(.25, .46, .45, .94);
  text-align: left;
  color: rgba(255, 255, 255, 0.7);
  margin-top: 8px;
  font-size: 17px;
}

.exploration-action p {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%; 
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

.exploration-action:hover {
  background-color: rgba(255, 255, 255, 0.05);
  cursor: pointer;
}

#create-exploration-action {
  color: rgba(255, 255, 255, 1);
  font-size: 17px;
}

#exploration-action-active {
  color: rgba(255, 255, 255, 1);
  background-color: rgba(255, 255, 255, 0.05);
}

#past-explorations {
  padding-right: 8px;
  overflow-y: auto;
}

#new-exploration {
  padding-right: 8px;
}

::-webkit-scrollbar {
  background: transparent; /* Makes scrollbar background transparent */
  width: 8px;
  padding: 0;
  margin: 0;
}

::-webkit-scrollbar-thumb {
  background:rgba(255, 255, 255, 0.2);
  padding: 0;
  margin: 0;
  border-radius: 100px;
}

#spinner {
  position: absolute;
  transform: translate(0, -50%);
  top: 50%;
  left: 55%;
  z-index: 4;
}

#modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  position: absolute;
}