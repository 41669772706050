#synthesis-modal {
    font-family: 'sohne';
    border-radius: 20px;
    background: #FAFAFD;
    box-shadow: 0px 5px 40px 0px rgba(25, 17, 61, 0.15);
    width: 978px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    z-index: 5;
}

#synthesis-modal-header {
    display: flex;
    width: 100%;
    height: 57px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 20px 20px 0px 0px;
    background: #E8E6F2;
}

#backdrop-window {
    position: absolute;
    backdrop-filter: blur(5px);
    z-index: 1;
    width: 100%;
    height: 100%;
}

#synthesis-modal-content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    width: 100%;
    height: 567px;
}

#synthesis-modal-summary-container {
    font-family: 'canela';
    padding: 1.5rem;
    margin: 0;
    text-align: left;
    width: 50%;
    background-color: #FAFAFD;
    border-radius: 0px 0px 0px 20px;
    line-height: 160%;
    color: #19113D;
    display: flex;
    flex-direction: column;
    gap: 28px;
    margin: 0;
}

#synthesis-modal-summary-container h2 {
    margin: 0;
    font-weight: 400;
}

#synthesis-modal-summary-container h4 {
    margin: 0;
    font-weight: 400;
}

#synthesis-modal-summary-container p {
    margin: 0;
    font-weight: 300;
}

#synthesis-modal-spinner {
    top: 45%;
    left: 45%;
    position: absolute;
    z-index: 4;
}

#synthesis-modal-summary-section {
    display: flex;
    flex-direction: column;
    gap: 28px;
    width: 103%;
    overflow-y: auto;
}

.synthesis-modal-summary-section-button {
    width: fit-content;
    background-color: transparent;
    color: #575073;
    display: flex;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    border: 1px solid #575073;
    gap: 4px;
    transition: 0.15s cubic-bezier(.25, .46, .45, .94);
    margin-bottom: 14px;
}

#synthesis-modal-summary-section-button-active {
    width: fit-content;
    background-color: #575073;
    color: white;
    display: flex;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    border: 1px solid #575073;
    gap: 4px;
    transition: 0.15s cubic-bezier(.25, .46, .45, .94);
}

.synthesis-modal-summary-section-button:hover {
    cursor: pointer;
    transform: scale(1.01);
}

#synthesis-modal-summary-text-container {
    padding-right: 18px;
    display: flex;
    flex-direction: column;
    gap: 8px;

}

::-webkit-scrollbar {
    background: transparent;
    /* Makes scrollbar background transparent */
    width: 8px;
    padding: 0;
    margin: 0;
}

::-webkit-scrollbar-thumb {
    background: rgba(87, 80, 115, 0.15);
    padding: 0;
    margin: 0;
    border-radius: 100px;
}

#synthesis-highlights-container {
    padding-right: 18px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: flex-start;
    align-items: flex-start;
    list-style: none;
}

#synthesis-highlights-container ul {
    list-style: none;
    padding: 0;
    margin: 0;
    font-weight: 300;
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

#synthesis-highlights-list-object {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

#synthesis-highlight-object {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 14px;
    border: 1px solid rgba(87, 80, 115, 0.3);
    background: transparent;
    margin: 0;
    padding: 16px;
    width: 360px;
    flex-shrink: none;
    color: #575073;
}

.synthesised-highlight-button {
    width: fit-content;
    background-color: transparent;
    color: #575073;
    display: flex;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    border: 1px solid #575073;
    gap: 4px;
    transition: 0.15s cubic-bezier(.25, .46, .45, .94);
    margin-bottom: 14px;
}

#synthesised-highlight-button-active {
    width: fit-content;
    background-color: #575073;
    color: white;
    display: flex;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    border: 1px solid #575073;
    gap: 4px;
    transition: 0.15s cubic-bezier(.25, .46, .45, .94);
}

.synthesised-highlight-button:hover {
    cursor: pointer;
    transform: scale(1.01);
}

#synthesis-modal-limitations-container {
    padding: 1.5rem;
    padding-right: 8px;
    text-align: left;
    width: 55%;
    border-radius: 0px 0px 20px 0px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    gap: 36px;
    margin: 0;
    font-family: 'canela';
    line-height: 160%;
    color: #19113D;
}

#synthesis-modal-urls-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
}

#synthesis-modal-urls-buttons p {
    font-weight: 400;
    margin: 0;
    font-size: 14px;
}

#synthesis-modal-url-button-active {
    background-color: #575073;
    color: white;
}

.synthesis-modal-url-button {
    width: fit-content;
    background-color: #F5F7FA;
    color: #505D73;
    display: flex;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    border: none;
    gap: 4px;
    transition: 0.15s cubic-bezier(.25, .46, .45, .94);
}

.synthesis-modal-url-button:hover {
    cursor: pointer;
    transform: scale(1.01);
}

#synthesis-modal-url {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 12px;
    height: auto;
    list-style: none;
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.synthesis-modal-url-object {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    border-radius: 14px;
    border: 1px solid rgba(87, 80, 115, 0.3);
    background: #FFF;
    margin: 0;
    padding: 0;
    padding: 16px;
    width: 410px;
    flex-shrink: none;
    font-family: 'sohne';
}


.synthesis-modal-url-object:hover {
    cursor: pointer;
}

#synthesis-modal-limitation-object:hover {
    cursor: default;
}

#synthesis-modal-url-object-active {
    color: white;
    background: #575073;
}

.synthesis-modal-url-object svg {
    flex-shrink: 0;

}

#synthesis-modal-url-card {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 12px;
}

.synthesis-modal-url-object h4 {
    font-weight: 400;
    margin: 0;
    padding: 0;
    font-size: 16px;
    color: rgba(87, 80, 115, 1);
}

#synthesis-modal-url-object-active h4 {
    color: white;
}

.synthesis-modal-url-object p {
    font-weight: 300;
    margin: 0;
    padding: 0;
    font-size: 16px;
    color: rgba(87, 80, 115, 1);
}

#synthesis-modal-url-object-active p {
    color: white;
}

#synthesis-modal-limitations-header {
    font-weight: 400;
    margin: 0;
    padding: 0;
}

#synthesis-modal-limitations-text {
    font-weight: 300;
    margin: 0;
    padding: 0;
}

#synthesis-modal-limitations-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
    overflow-y: auto;
    padding-right: 18px;
}

#synthesis-modal-limitations-description {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
}

#synthesis-modal-interview-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 14px;
    font-weight: 300;
}

#synthesis-modal-interview-stakeholder {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

#synthesis-modal-interview-content-divider {
    height: 1px;
    align-self: stretch;
    background: white;
}

#synthesis-modal-summary-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
}

#synthesis-modal-summary-header h4 {
    font-size: 18px;
    font-weight: 300;
}

#synthesis-placeholder-content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #19113D;
}

#synthesis-placeholder-content p {
    font-size: 18px;
}